// 1. FONTS FAMILIES
$primary-font: "Roboto", sans-serif;

// 2. COLORS
$primary-color: #24b7af;
$primary-color-tint: #e9f8f7;
$secondary-color: #bf328d;
$black: #000;
$white: #fff;
// GRAYS
$transparent-black: #00000066;
$alabaster: #f9f9f9;
$athens-gray: #efeff4;
$athens-gray-alt: #eeeef0;
$black-squeeze: #ebf7f7;
$bright-gray: #373f4e;
$concrete: #f2f2f2;
$dusty-gray: #999999;
$french-gray: #c6c6c8;
$gray: #878787;
$humming-bird: #cfecf8;
$jumbo: #767680;
$mako: #434a54;
$mischa: #dadade;
$mystic: #e3e9ec;
$tuna: #3c3c43;
$footer-color-no-transparency: #fafafa;
$paynesGreyTransparent: #3c3c434a;
$lightGrey: #d3d3d3;
$shuttleGray: #5f6571;
$silverChalice: #a3a3a3;
$whiteIce: #f4fdfb;
$doveGray: #737373;
$silver: #c2c2c2;
// Other colors
$violet: #1a0b38;
$eastern-blue: #247eb7;
$error-color: #ff3131;
$success-color: #19aa00;
$warning-color: #f0be00;
$empire-color: #f45417;
$alternate-login: #d01390;
$polar: #f3fcfb;
$lightWhisper: #efeeee;
$whisper: #ebe9e9;
$abbey: #54565b;
$mulberry: #c3539b;
$fountain-blue: #4cc2bd;
$chablis: #fff5f5;
$red-orange: #ff3232;
$hopbush: #d26faf;
// Temp colors
$primary-color-lighter: #9bd9d9;

// 3. FONT WIGHTS
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// 4. GLOBAL WIDTHS
$mobile-menu-width: calc(100% - 44px);
$menu-width: rem-calc(314);
$screen-width: rem-calc(375);
$menu-width-em: 19.625em;

// 5. GRID BREAKPOITS
// 689px je breakpoint za prikaz za menika i 1 ekran 43.0625em
// 1064px je breakpoint za prikaz za menija i 2 ekrana 66.5em
// 1439px je breakpoint za prikaz za menija i 3 ekrana 89.9375em
// 1814px je breakpoint za prikaz za menija i 4 ekrana 113.375em
$grid-values: (
  oneScreenWithMenuAndUp: 43.0625em,
  twoScreensWithMenuAndUp: 66.5em,
  threeScreensWithMenuAndUp: 89.9375em,
  fourScreensWithMenuAndUp: 113.375em,
  fiveScreensWithMenuAndUp: 136.8125em,
  sixScreensWithMenuAndUp: 160.25em,
  sevenScreensWithMenuAndUp: 183.6875em
);

$grid-breakpoints: (
  // one screen and menu visible
    one: "min-width: #{map-get($grid-values, oneScreenWithMenuAndUp)}",
  // two screens and menu visible visible
    two: "min-width: #{map-get($grid-values, twoScreensWithMenuAndUp)}",
  // three screens and menu visible visible
    three: "min-width: #{map-get($grid-values, threeScreensWithMenuAndUp)}",
  // four screens and menu visible visible
    four: "min-width: #{map-get($grid-values, fourScreensWithMenuAndUp)}",
  // five screens and menu visible visible
    five: "min-width: #{map-get($grid-values, fiveScreensWithMenuAndUp)}",
  // six screens and menu visible visible
    six: "min-width: #{map-get($grid-values, sixScreensWithMenuAndUp)}",
  // seven screens and menu visible visible
    seven: "min-width: #{map-get($grid-values, sevenScreensWithMenuAndUp)}"
);

$touch-device-query: "(hover: none) and (pointer: coarse)";

// 5. Z-INDEX
$zindex: (
  screen-modal: 9,
  menu: 10,
  loader: 11
);
