@import "~@/styles/util/_index.scss";

.clebex-section-input :deep() {
  .colors,
  .color-type,
  .color-show {
    display: none !important;
  }
  .hu-color-picker {
    margin-left: 75px;
    box-shadow: none;
    &.light {
      background-color: $white;
    }
  }
}
